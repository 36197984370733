.flashmessage {
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding: 0.5rem 1rem;
    background: var(--black);
    color: var(--white);
    transform: translateY(-200%);
    transition: transform 0.5s;
    border-radius: 0.5rem;
    z-index: 3;
}
.flashmessage--visible {
    transform: translateY(0);
}
