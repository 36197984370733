.salienceicon {
    display: inline-block;
    width: 2rem;
    text-align: right;
    height: 1rem;
    background-size: 0.9rem, 0.9rem;
    background-position: 0 0, 1rem 0;
    background-repeat: no-repeat;
}
.salienceicon div {
    position: absolute;
    top: -1000px;
    left: -1000px;
    width: 0px;
    height: 0px;
}
.salienceicon.salienceicon--showlabel div {
    position: inline;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
}
.salienceicon svg {
    height: 100%;
    width: auto;
}
