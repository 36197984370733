.beliefpage-subtitle {
    padding: 0 0 0.5rem 0;
    font-size: 0.8rem;
    color: var(--grey--dark);
    margin-top: -0.8rem;
}

.beliefpage__actions > *{
    margin-right: 0.5rem;
}
