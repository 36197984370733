.reasonlist-list {
    list-style: none;
    padding: 0;
}
.reasonlist-item {
    position: relative;
    padding: 0.5rem 0 0.5rem 4.5rem;
    margin-bottom: 0.5rem;
    min-height: 2rem;
}
.reasonlist-item__header {
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
}
.reasonlist-item__relevance {
    font-size: 0.8rem;
    color: var(--grey--dark);
    margin: 0.25rem 0 0 0;
}
.reasonlist-item__salienceicons {
    position: absolute;
    top: 0.5rem;
    left: 0;
}
.reasonlist-item__salienceicons--modified {
    position: absolute;
    top: 1.5rem;
    left: 0;
}
.reasonlist-item .form {
    margin: 1rem 0 1rem -4.5rem;
    padding: 1rem 0;
    border: 1px dashed var(--black);
    border-width: 1px 0 1px 0;
}
.reasonlist-item__buttons {
    margin-top: 0.5rem;
}
.reasonlist-item__buttons > * {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}
.reasonlist-item--added, .reasonlist-item__salienceicons--added {
    background-color: var(--diff--insert);
}
.reasonlist-item--deleted, .reasonlist-item__salienceicons--deleted {
    background-color: var(--diff--delete);
}
