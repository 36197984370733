html {
    --black: #000000;
    --white: #ffffff;
    --grey: #aaaaaa;
    --grey--dark: #333333;
    --grey--light: #dddddd;
    --font: sans-serif;
    --diff--delete: #f7d1d1;
    --diff--insert: #d1f7d1;
}
html.dark-mode {
    --black: #ccc;
    --white: #111;
    --grey: #bbb;
    --grey--dark: #999;
    --grey--light: #333;
    --font: sans-serif;
    --diff--delete: #530f0f;
    --diff--insert: #0f531d;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: var(--font);
    font-size: 16px;
    background: var(--white);
    color: var(--black);
}

body {
    margin: 1rem;
}

h2 {
    font-size: 2rem;
    margin: 1.2rem 0;
}

a {
    color: var(--black);
    text-decoration: underline;
}
a:hover, a:active {
    color: var(--grey--dark);
}

.container {
    max-width: 800px;
    margin: 0 auto;
}

.button {
    background: var(--white);
    color: var(--black);
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 2px solid var(--black);
    cursor: pointer;
}
.button:hover, .button:focus {
    background: var(--grey--light);
}
.button:active {
    transform: translate(2px, 2px);
    box-shadow: -2px -2px var(--black);
}
.button:disabled, .button:active:disabled {
    background: var(--grey--light);
    border-color: var(--grey--dark);
    color: var(--grey--dark);
    transform: none;
    box-shadow: unset;
}
.button.button--secondary {
    border: 0;
}
.button.button--warning {
    background: black;
    color: white;
}
.button.button--warning:hover {
    background: var(--grey);
}
.button.button--small {
    padding: 0 0.5rem;
}
.button--link, .button--link:active, .button--link:hover, .button--link:focus {
    border: 0;
    text-decoration: underline;
    background: transparent;
    box-shadow: unset;
    transform: none;
}
.button--link:hover, .button--link:active {
    color: var(--grey--dark);
}

.form--stacked label {
    display: block;
}
@media screen and min-width(600px) {
    .form--stacked {
        display: grid;
        grid-template-columns: [left] minmax(50px, max-content) [right] auto;
    }

    .form--stacked label {
        grid-column: left;
        text-align: right;
        padding: 0 0.5rem;
    }
    .form--stacked .form__longdesc, .form--stacked .form__input {
        grid-column: right;
    }
    .form__actions {
        grid-column: right;
    }
}
.form__actions > *+* {
    margin-left: 0.5rem;
}

input[type=text].form__input, textarea.form__input, select.form__input {
    background: var(--white);
    color: var(--black);
    border: 1px solid var(--grey);
    border-radius: 0.25rem;
    min-height: 1rem;
    padding: 0.25rem;
    font-size: 1rem;
    font-family: var(--font);
    width: 100%;
    box-sizing: border-box;
}
.form__longdesc {
    font-size: 0.8rem;
    margin: 0 0 1rem 0;
    color: var(--grey--dark);
}

.header {
    border-bottom: 1px solid var(--grey);
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
}
.header h1 {
    font-size: 1rem;
    font-weight: bold;
    color: var(--grey--dark);
    flex-grow: 1;
}

.nav__state {
    display: none;
}
@media screen and (max-width: 600px) {
    .nav__state:checked + .nav__items {
        display: block;
    }
    .nav__button--close, .nav__state:checked ~ .nav__button--open {
        display: none;
    }
    .nav__state:checked ~ .nav__button--close {
        display: block;
        position: fixed;
        top: 1rem;
        right: 1rem;
    }
    .nav__button {
        z-index: 2;
        font-size: 2rem;
        border-radius: 0.5rem;
        cursor: pointer;
        width: 2rem;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .nav__button:hover {
        background: var(--grey--light);
    }
    .nav__items {
        position: fixed;
        display: none;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        padding-top: 4rem;
        background: var(--white);
    }
    .nav__items *, .nav__items *:active, .nav__items *:hover {
        display: block;
        width: 100%;
        text-decoration: none;
        text-align: center;
        border-radius: 0;
        padding: 1rem 0;
        font-size: 1rem;
    }
    .nav__items *:hover {
        background: var(--grey--light);
    }
}

@media screen and (min-width: 601px) {
    .nav__button {
        display: none;
    }
    .nav__items * {
        padding: 0;
        font-size: 0.9rem;
    }
    .nav__items > *+*{
        margin-left: 0.5rem;
    }
}

.disclaimer {
    margin: -1rem 0 0 0;
    border: 1px dashed var(--grey--dark);
    color: var(--grey--dark);
    font-size: 0.8rem;
    border-width: 1px 0;
    padding: 0.5rem 0;
}
